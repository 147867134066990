<template>
  <div>
    <div>
      <div class="page-title">
        <h3>Month To Date</h3>
        <div class="page-breadcrumb">
          <ol class="breadcrumb">
            <li>
              <router-link
                :to="{name: 'home'}"
              >
                Home
              </router-link>
            </li>
            <li>
              <router-link
                :to="{name: 'dashboard'}"
              >
                Dashboard
              </router-link>
            </li>
            <li class="active">
              Month To Date
            </li>
          </ol>
        </div>
      </div>
    </div>
    <div id="main-wrapper">
      <div class="row">
        <div class="col-xs-12">
          <div class="panel panel-white">
            <div class="panel-heading">
              <h3 class="panel-title">
                {{ getMonth('Current') + ' Breakdown' }}
              </h3>
            </div>
            <div class="panel-body">
              <div class="table-responsive">
                <table
                  id="currentMonthStatisticsList"
                  class="table table-striped table-bordered"
                  style="width:100%"
                >
                  <thead>
                    <tr id="currentMonthColumns">
                      <th class="status">
                        Status
                      </th>
                      <th class="priority">
                        Sort Order
                      </th>
                      <th class="total">
                        Total
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div><!-- Row -->
      <div class="row">
        <div class="col-xs-12">
          <div class="panel panel-white">
            <div class="panel-heading">
              <h3 class="panel-title">
                {{ getMonth('Last') + ' Breakdown' }}
              </h3>
            </div>
            <div class="panel-body">
              <div class="table-responsive">
                <table
                  id="lastMonthStatisticsList"
                  class="table table-striped table-bordered"
                  style="width:100%"
                >
                  <thead>
                    <tr id="lastMonthColumns">
                      <th class="status">
                        Status
                      </th>
                      <th class="priority">
                        Sort Order
                      </th>
                      <th class="total">
                        Total
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div><!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-white">
            <div class="panel-heading clearfix">
              <h2 class="panel-title text-center">
                Forms
              </h2>
            </div>
            <div class="panel-body">
              <div class="table-responsive">
                <table
                  id="mtdFormsList"
                  class="table table-striped table-bordered"
                  style="width:100%"
                >
                  <thead>
                    <tr>
                      <th class="supervisor includeInExport">
                        Supervisor
                      </th>
                      <th class="owner includeInExport">
                        Owner
                      </th>
                      <th class="status includeInExport">
                        Status
                      </th>
                      <th class="bankVerificationEpoch includeInExport">
                        Bank Verification Date
                      </th>
                      <th class="firstName includeInExport">
                        First Name
                      </th>
                      <th class="lastName includeInExport">
                        Last Name
                      </th>
                      <th class="mobileNumber">
                        Mobile Number
                      </th>
                      <th class="updatedAt includeInExport">
                        Updated On
                      </th>
                      <th class="updatedAtTime includeInExport">
                        Updated Time
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div><!-- Row -->
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {API, graphqlOperation} from "aws-amplify";
import * as customQueries from "../../graphql/customQueries";

export default {
  name: "MonthToDate",
  data() {
    return {
      jobType: null,
      formsTableData: [],
      formsTable: null,
      currentMonthForms: [],
      currentMonthTeamTableData: [],
      currentMonthTeamTable: null,
      lastMonthForms: [],
      lastMonthTeamTableData: [],
      lastMonthTeamTable: null
    }
  },
  computed: {
    ...mapGetters([
      "getUsers",
      "getTitles",
      "getStatuses",
      "getLoggedInUser"
    ]),
    ...mapState([
      'forms',
      'updateSubscriptionForm',
      'deleteSubscriptionForm'
    ]),
    getMonth() {
      return (type) => {
        return type === 'Current' ? moment().format('MMMM') : moment().subtract(1, 'months').format('MMMM');
      };
    }
  },
  mounted() {
    $('#sidebar').removeClass('visible');
    $('.page-inner').removeClass('sidebar-visible');

    if (this.getLoggedInUser && this.getLoggedInUser !== '') {
      if (this.getLoggedInUser.jobTitle && this.getLoggedInUser.jobTitle !== '') {
        let jobTitle = _.find(this.getTitles, (title) => {
          return title.id === this.getLoggedInUser.jobTitle;
        });
        if (jobTitle) {
          this.jobType = jobTitle.access;

          this.createTeamTable('Current');
          this.createTeamTable('Last');
          this.createFormsTable();

          this.fetchForms('Current');
          this.fetchForms('Last');
        }
      }
    }
  },
  methods: {
    prettyPrint: function (value, type) {
      if (type === 'User') {
        let user = _.find(this.getUsers, (user) => {
          return user.username === value;
        });
        if (user) {
          return _.upperCase(user.firstName + ' ' + user.lastName);
        } else {
          return value;
        }
      } else if (type === 'Status') {
        return _.result(_.find(this.getStatuses, (status) => {
          return status.id === value;
        }), 'name') || '';
      }
    },
    _getTeams: function () {
      return _.filter(this.getUsers, (user) => {
        return user.id !== this.getLoggedInUser.id
            && user.supervisor === this.getLoggedInUser.id
            && _.includes(['a23cc15d-cec7-45fa-8cdc-6eb434287053', '47fe7ed6-96e8-469a-8478-402dd5904ef0', 'b7412fb2-ffef-4288-82e4-37f49b7f34c7'], user.jobTitle);
      });
    },
    async fetchForms(type) {
      let statuses = _.filter(this.getStatuses, (status) => {
        return (type === 'Current' ? _.includes(status.currentMonth, this.jobType) : _.includes(status.lastMonth, this.jobType));
      });
      let verificationDateEpoch = moment().startOf('month').format('X');
      let filter = {};
      if (this.jobType === 'Manager') {
        filter.branchManager = {
          eq: this.getLoggedInUser.username
        };
      } else if (this.jobType === 'Supervisor') {
        filter.supervisor = {
          eq: this.getLoggedInUser.username
        };
      }
      _.forEach(statuses, (status) => {
        this.fetchFormsByStatusVerification(type, status.id, verificationDateEpoch, filter);
      });
    },
    async fetchFormsByStatusVerification(type, status, verificationDateEpoch, filter = null, nextPage = null) {
      try {
        let bankVerificationEpoch = {};
        if (type === 'Current') {
          bankVerificationEpoch = {
            ge: verificationDateEpoch
          };
        } else if (type === 'Last') {
          bankVerificationEpoch = {
            lt: verificationDateEpoch
          }
        }
        const {
          data: {
            formsByStatusVerificaiton: {items, nextToken}
          }
        } = await API.graphql(graphqlOperation(customQueries.countFormsByStatusVerificationMinimal, {
          status: status,
          bankVerificationEpoch: bankVerificationEpoch,
          limit: 999,
          filter: filter,
          nextToken: nextPage
        }));
        if (type === 'Current') {
          this.currentMonthForms = (_.uniqBy(_.concat(this.currentMonthForms, items), 'id'));
          this.updateTeamTableData(type);
        } else if (type === 'Last') {
          this.lastMonthForms = (_.uniqBy(_.concat(this.lastMonthForms, items), 'id'));
          this.updateTeamTableData(type);
        }

        nextPage = nextToken;
        if (nextPage) {
          this.fetchFormsByStatusVerification(type, status, verificationDateEpoch, filter, nextPage);
        }
      } catch (err) {
        console.log(err);
      }
    },
    createFormsTable: function () {
      this.formsTable = $('#mtdFormsList').DataTable({
        processing: true,
        language: {
          'loadingRecords': '&nbsp;',
          'processing': '<i class="fas fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'
        },
        dom: 'Bfrtip',
        lengthMenu: [
          [10, 25, 50, 100, -1],
          ['10 rows', '25 rows', '50 rows', '100 rows', 'Show all']
        ],
        data: this.formsTableData,
        rowId: 'id',
        columns: [
          {
            data: 'supervisor'
          },
          {
            data: 'owner'
          },
          {
            data: 'status'
          },
          {
            data: 'bankVerificationEpoch',
            defaultContent: ""
          },
          {
            data: 'firstName'
          },
          {
            data: 'lastName'
          },
          {
            data: 'mobileNumber'
          },
          {
            data: 'updatedAt'
          },
          {
            data: 'updatedAt'
          }
        ],
        columnDefs: [
          {
            render: (data) => {
              return this.prettyPrint(data, 'User');
            },
            targets: "supervisor"
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'User');
            },
            targets: "owner"
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Status');
            },
            targets: "status"
          },
          {
            render: (data) => {
              if (!data || data === '') {
                return '';
              } else {
                return moment(data, "X").format('MMMM Do YYYY h:mm A');
              }
            },
            targets: "bankVerificationEpoch"
          },
          {
            render: (data) => {
              if (_.includes(data, 'T')) {
                return moment(data).format('DD-MM-YYYY');
              } else if (!data || data === '') {
                return '';
              } else {
                return moment(data, "YYYY-MM-DD").format('DD-MM-YYYY');
              }
            },
            targets: "updatedAt"
          },
          {
            render: (data) => {
              if (_.includes(data, 'T')) {
                return moment(data).format('h:mm A');
              } else {
                return '';
              }
            },
            targets: "updatedAtTime"
          },
          {
            visible: false,
            targets: this.jobType === 'Supervisor' ? 'supervisor' : ''
          }
        ],
        buttons: [
          {
            extend: 'pageLength',
            text: '<i class="fas fa-filter"></i>',
            titleAttr: 'Number of Rows'
          },
          {
            extend: 'excelHtml5',
            autoFilter: true,
            text: '<i class="far fa-file-excel"></i>',
            titleAttr: 'Excel',
            exportOptions: {
              columns: '.includeInExport'
            }
          }
        ],
        search: {
          regex: true,
          smart: false
        },
        order: [[7, "desc"], [8, "desc"]]
      });

      this.formsTable.buttons()
          .container()
          .appendTo('#mtdFormsList_wrapper .col-sm-6:eq(0)');
    },
    createTeamTable: function (type) {
      _.forEach(this._getTeams(), (team) => {
        $('<th id="' + team.username + '" class="' + team.username + '">' + _.upperCase(team.firstName + ' ' + team.lastName) + '</th>').appendTo(type === 'Current' ? '#currentMonthColumns' : '#lastMonthColumns');
      });
      let datatableObject = {
        processing: true,
        language: {
          'loadingRecords': '&nbsp;',
          'processing': '<i class="fas fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'
        },
        dom: 'Bfrt',
        paging: false,
        data: type === 'Current' ? this.currentMonthTeamTableData : this.lastMonthTeamTableData,
        rowId: 'name',
        columns: [
          {
            name: 'status',
            data: 'name'
          },
          {
            name: 'priority',
            data: 'priority'
          },
          {
            name: 'total',
            data: 'total',
            defaultContent: ""
          },
          ..._.map(this._getTeams(), (user) => {
            return {
              name: user.username,
              data: user.username,
              defaultContent: ""
            }
          })
        ],
        columnDefs: [
          {
            render: (data) => {
              if (data && data !== 'ALL STATUSES') {
                return this.prettyPrint(data, 'Status');
              } else {
                return data;
              }
            },
            targets: [0]
          },
          {
            visible: false,
            targets: "priority"
          },
          {
            className: "font-weight-bold text-danger",
            targets: "total"
          }
        ],
        "fnRowCallback": function (nRow, aData) {
          $(nRow).removeClass('font-weight-bold');
          $(nRow).removeClass('text-danger');
          if (aData.name === 'ALL STATUSES') {
            $(nRow).addClass('font-weight-bold');
            $(nRow).addClass('text-danger');
          }
        },
        buttons: [
          {
            extend: 'excelHtml5',
            autoFilter: true,
            text: '<i class="far fa-file-excel"></i>',
            titleAttr: 'Excel'
          }
        ],
        search: {
          regex: true,
          smart: false
        },
        select: {
          style: 'os',
          items: 'cell'
        },
        order: [[1, 'asc'], [0, "asc"]]
      };
      if (type === 'Current') {
        this.currentMonthTeamTable = $('#currentMonthStatisticsList').DataTable(datatableObject);
        this.currentMonthTeamTable.buttons()
            .container()
            .appendTo('#currentMonthStatisticsList_wrapper .col-sm-6:eq(0)');
        this.currentMonthTeamTable.on('select', () => {
          this.teamTableCellClick('Current')
        });
        this.currentMonthTeamTable.on('deselect', this.teamTableCellClick);
      } else {
        this.lastMonthTeamTable = $('#lastMonthStatisticsList').DataTable(datatableObject);
        this.lastMonthTeamTable.buttons()
            .container()
            .appendTo('#lastMonthStatisticsList_wrapper .col-sm-6:eq(0)');
        this.lastMonthTeamTable.on('select', () => {
          this.teamTableCellClick('Last')
        });
        this.lastMonthTeamTable.on('deselect', this.teamTableCellClick);
      }
    },
    async teamTableCellClick(type) {
      $('.status-active-row').removeClass('status-active-row');
      $('.team-active-column').removeClass('team-active-column');
      if (type === 'Current') {
        this.lastMonthTeamTable.cells('.selected').deselect();
        this.currentMonthTeamTable.rows(_.map(this.currentMonthTeamTable.cells({selected: true})[0], 'row'))
            .nodes()
            .to$()
            .addClass('status-active-row');
        this.currentMonthTeamTable.columns(_.map(this.currentMonthTeamTable.cells({selected: true})[0], 'column'))
            .header()
            .to$()
            .not('.status, .total')
            .addClass('team-active-column');
        this.updateFormsTableData(type);
      } else {
        this.currentMonthTeamTable.cells('.selected').deselect();
        this.lastMonthTeamTable.rows(_.map(this.lastMonthTeamTable.cells({selected: true})[0], 'row'))
            .nodes()
            .to$()
            .addClass('status-active-row');
        this.lastMonthTeamTable.columns(_.map(this.lastMonthTeamTable.cells({selected: true})[0], 'column'))
            .header()
            .to$()
            .not('.status, .total')
            .addClass('team-active-column');
        this.updateFormsTableData(type);
      }
    },
    async updateTeamTableData(type) {
      let teamData = type === 'Current' ? _.groupBy(this.currentMonthForms, 'status') : _.groupBy(this.lastMonthForms, 'status');
      if (type === 'Current') {
        if (this.currentMonthTeamTable) {
          this.currentMonthTeamTableData = this.getTableFormStats(teamData);
          teamData = this.jobType === 'Manager' ? _.groupBy(this.currentMonthForms, 'supervisor') : _.groupBy(this.currentMonthForms, 'owner');
          let teamTotalRow = {
            'name': 'ALL STATUSES',
            'priority': 0,
            'total': this.currentMonthForms.length
          };
          _.forEach(teamData, (teamForms, team) => {
            teamTotalRow[team] = teamForms.length;
          });
          this.currentMonthTeamTableData.unshift(teamTotalRow);
          this.currentMonthTeamTable.clear();
          this.currentMonthTeamTable.rows.add(this.currentMonthTeamTableData);
          this.currentMonthTeamTable.draw();
        }
      } else {
        if (this.lastMonthTeamTable) {
          this.lastMonthTeamTableData = this.getTableFormStats(teamData);
          teamData = this.jobType === 'Manager' ? _.groupBy(this.lastMonthForms, 'supervisor') : _.groupBy(this.lastMonthForms, 'owner');
          let teamTotalRow = {
            'name': 'ALL STATUSES',
            'priority': 0,
            'total': this.lastMonthForms.length
          };
          _.forEach(teamData, (teamForms, team) => {
            teamTotalRow[team] = teamForms.length;
          });
          this.lastMonthTeamTableData.unshift(teamTotalRow);
          this.lastMonthTeamTable.clear();
          this.lastMonthTeamTable.rows.add(this.lastMonthTeamTableData);
          this.lastMonthTeamTable.draw();
        }
      }
      this.updateFormsTableData();
    },
    getTableFormStats: function (forms) {
      let initTableData = [];
      _.forEach(forms, (statusForms, status) => {
        let teamTableRow = {
          'name': status,
          'priority': 1,
          'total': statusForms.length
        };
        let teamData = this.jobType === 'Manager' ? _.groupBy(statusForms, 'supervisor') : _.groupBy(statusForms, 'owner');
        _.forEach(teamData, (forms, formOwner) => {
          teamTableRow[formOwner] = forms.length;
        });
        initTableData.push(teamTableRow);
      });
      return initTableData;
    },
    async updateFormsTableData(type = null) {
      this.formsTableData = _.concat(this.currentMonthForms, this.lastMonthForms);
      let teams = this._getTeams();
      let selectedTeam = $('.team-active-column');
      if (selectedTeam.length > 0) {
        teams = _.filter(this.getUsers, (user) => {
          return _.includes(_.map(selectedTeam, 'id'), user.username);
        });
      }
      if (type) {
        if (type === 'Current') {
          let selectedRows = this.currentMonthTeamTable.rows('.status-active-row').data();
          if (selectedRows.length > 0) {
            let statuses = _.map(selectedRows, 'name');
            this.formsTableData = _.filter(this.currentMonthForms, (form) => {
              return (_.includes(statuses, form.status) || _.includes(statuses, 'ALL STATUSES'))
                  && ((this.jobType === 'Manager' && _.includes(_.map(teams, 'username'), form.supervisor))
                      || (this.jobType === 'Supervisor' && _.includes(_.amp(teams, 'username'), form.owner)));
            });
          }
        } else {
          let selectedRows = this.lastMonthTeamTable.rows('.status-active-row').data();
          if (selectedRows.length > 0) {
            let statuses = _.map(selectedRows, 'name');
            this.formsTableData = _.filter(this.lastMonthForms, (form) => {
              return (_.includes(statuses, form.status) || _.includes(statuses, 'ALL STATUSES'))
                  && ((this.jobType === 'Manager' && _.includes(_.map(teams, 'username'), form.supervisor))
                      || (this.jobType === 'Supervisor' && _.includes(_.amp(teams, 'username'), form.owner)));
            });
          }
        }
      }
      if (this.formsTable) {
        this.formsTable.clear();
        this.formsTable.rows.add(this.formsTableData);
        this.formsTable.draw();
      }
    }
  }
}
</script>

<style scoped>

</style>
